.map-container{
    width: 36rem;
    height: 18rem;
    border-radius: 1rem;

}

.container {
    padding: 0rem auto;
    filter: drop-shadow(0 0 0.75rem black);
}
.sidebar {
    /* display: flex;
    flex-direction: row-reverse; */
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 59%;
    margin: 12px;
    border-radius: 4px;
}

@media only screen and (max-width: 800px) {
   
  
    .map-container{
        width: 30rem;
        margin-top: 4rem;
    }
  
  }