.loader-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../public/bg.png');
}

.loader-backdrop img {
    width: 30%;
    border-radius: 50%;
}

@media screen and (max-width: 900px) {
    .loader-backdrop img {
        width: 50%;
    }
}