.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: rgb(16, 16, 16);
  border: 2px solid white;
  padding: 1.5rem 1rem;
}

.container:hover {
  box-shadow: 0 0 20px white;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transform: scale(1.05);
  transition: all 0.3s;
  border: 2px solid #fcc200;
}

.container:hover > .img_container {
  background-color: #fcc200;
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.container:hover > div > .social_icon {
  background-color: #fcc200;
  color: black;
  border-radius: 50%;
  padding: 0.2em;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_icon {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  margin-right: 0.25em;
}

.img_container {
  padding: 0.3em 0.3em;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 1em;
}

.social_container {
  display: flex;
  justify-content: center;
  padding: 0em 0.25em;
  color: #fcc200;
  width: 250px;
  align-items: center;
  text-decoration: none;
}

.social_handle {
  font-size: 1.35em;
}

.name {
  font-weight: 800;
  font-size: 1.35em;
  padding: 0.175em 0em;
  color: white;
}

.role {
  font-weight: 400;
  font-size: 1.25rem;
  padding: 0.175em 0em;
  color: rgb(176, 176, 176);
  margin: 0.3em 0em;
  opacity: 75%;
}

/* style={{
  borderRadius: "50%",
  height: "200px",
  Weight: "200px",
  objectFit: "contain"
}} */

.img {
  border-radius: 50%;
  height: 185px;
  width: 185px;
  object-fit: cover;
}
