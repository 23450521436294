@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

:root {
  --marquee-width: 100%;
  --marquee-height: 20vh;
  --marquee-elements: 10;
  --marquee-elements-displayed: 5;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 5s);
}

/* Applying CSS to sphere */
.tagcloud {
  font-weight: bold;
  font-size: 20px;
  width: 300px;
  margin: auto;
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
  color: #fcc200;
  font-family: "Poppins", sans-serif;
}

/*  */

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);

  color: #eee;
  overflow: hidden;
  position: relative;
}
.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}
.marquee:before {
  left: 0;
  background: linear-gradient(to right, black, 0%, transparent 100%);
}
.marquee:after {
  right: 0;
  background: linear-gradient(to left, black, 0%, transparent 100%);
}
.marquee-content.right {
  list-style: none;
  height: 80%;
  display: flex;
  animation: scrolling-1 var(--marquee-animation-duration) linear infinite;
  padding: 1rem;
}
.marquee-content.left {
  list-style: none;
  height: 80%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
  padding: 1rem;
}

/* .marquee-content:hover {
  animation-play-state: paused;
} */
@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}

@keyframes scrolling-1 {
  0% {
    transform: translateX(
      calc(
        -1 * var(--marquee-element-width) * var(--marquee-elements) + var(--marquee-width)
      )
    );
  }
  100% {
    transform: translateX(0);
  }
}

.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  font-size: calc(var(--marquee-height) * 3 / 4); /* 5rem; */
  white-space: nowrap;
  margin: 5px;
  padding: 0 1rem;
}

.marquee-content li img {
  width: 100%;
  height: 100%;
}

.spns-1 {
  color: black;
}

.spns1 {
  color: white;
}

.past-sponser {
  min-height: 120vh;
  height: fit-content;
  margin: auto;
  /* display: flex; */
  width: 100%;
  margin-bottom: 100px;
}

.sponser-upper {
  display: flex;
  min-height: 80vh;
  /* padding: 50px 40px; */
  width: 100%;
}

.sponser-left,
.sponser-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sponser-left span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100000;
}

.sponser-heading {
  font-family: "Klarissa";
  color: #fcc200;
  font-size: 3rem;
  font-weight: 600;
  margin: 40px 0;
}

.sponser-para {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  width: 80%;
}

@media (max-width: 850px) {
  .past-sponser {
    margin-bottom: 90px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
  :root {
    --marquee-width: 100%;
    --marquee-height: 15vh;
    --marquee-elements-displayed: 3;
  }
  .marquee:before,
  .marquee:after {
    width: 5rem;
  }
}

@media (max-width: 1024px) {
  .tagcloud {
    width: 300px;
  }

  .sponser-upper {
    flex-direction: column;
  }

  .sponser-left,
  .sponser-right {
    width: 100%;
    text-align: center;
    margin: 1rem 0;
    align-items: center;
  }

  .tagcloud--item {
    font-size: 1rem;
  }
}

.container {
  width: 100wh;
}

.sponser-left {
  padding-left: 40px;
}

@media only screen and (max-width: 1030px) {
  .sponser-left {
    padding-left: 0px;
  }
}
