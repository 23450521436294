.about-text {
    color: white;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 1.2rem;
}

.page-section {
    padding: 40px;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: black; */
}

.img-container {
    height: 350px;
    width: 350px;
    overflow: hidden;
    margin: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.text-container {
    flex: 1;
}

.about-title {
    font-size: 3rem;
    font-family: 'Klarissa';
    color: #fcc200;
    margin-bottom: 40px;

}

@media screen and (max-width: 980px) {
    .page-section {
        flex-direction: column;
        padding: 20px 100px;
    }
    .about-title {
        text-align: center;
    }
    .about-text {
        text-align: center;
    }
    .img-container {
        margin: 0;
    }
    .text-container {
        margin-top: 20px;
    }
}

@media screen and (max-width: 790px) {
    .page-section {
        padding: 20px 40px;
    }
}