.start-section {
    width: 100vw;
    height: 100vh;
    border-bottom: 2px solid white;
    position: relative;
}

.four-point-star::after,
.four-point-star::before {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 48%);
    content: "\2726";
    font-size: 5rem;
}

.four-point-star::after { /* The foreground star */
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.four-point-star::before { /* The star shadow */
    color: transparent;
    text-shadow: 2px 3px 30px white;
}

.four-point-star {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 48%);
    bottom: 0;

}

.parallax-img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.parallax-img-container img {
    height: 100%;
}

.adjust-height img{
    flex-shrink: 0;
    min-height: 100%;
    min-width: 100%;
}

.coming-soon {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    top: 50vh;
    width: 100%;
    left: 50vw;
    transform: translateX(-50%);
}

.coming-soon h1 {
    font-size: 5rem;
    font-family: 'Berkshire';
    /* border: 2px solid #ffe97e; */
    border-radius: 50px;
    /* background-color: rgb(30, 14, 25); */
    text-align: center;
    letter-spacing: 3px;
    animation: blink 2s linear infinite;
    /* background-image: linear-gradient(180deg, #ffe97e, rgb(30, 14, 25)); */
    color: #fcc200;
}

.resonanz-title {
  margin-bottom: 0;
}

.coming-soon .dates {
  margin-top: -20px;
  padding: 0;
  font-size: 2.5rem;
}

@keyframes blink{
    0%{
      opacity: 0.4;
    }
    80%{
      opacity: 1;
    }
    100%{
      opacity: 0.4;
    }
}

@media screen and (max-width: 886px) {
    .coming-soon h1 {
      font-size: 3rem;
    }
    .coming-soon .dates {
      margin-top: 0;
    }
    .coming-soon {
      top: 60vh;
    }
}
  