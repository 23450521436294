/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */

@font-face {
  font-family: 'Berkshire';
  src: url(./fonts/Berkshire.ttf) format('truetype');
}

@font-face {
  font-family: 'Kalligraaf';
  src: url(./fonts/Kalligraaf.ttf) format('truetype');
}

@font-face {
  font-family: 'Klarissa';
  src : url(./fonts/Klarissa.ttf) format('truetype');
}

@font-face {
  font-family: 'Mustopha';
  src : url(./fonts/Mustopha.ttf) format('truetype');
}

@font-face {
  font-family: 'Ramadhan';
  src : url(./fonts/Sketsa\ Ramadhan.ttf) format('truetype');
}

@font-face {
  font-family: 'Fontastique';
  src : url(./fonts/Fontastique.ttf) format('truetype');
}

@font-face {
  font-family: 'Hidayatullah';
  src : url(./fonts/hidayatullah.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-image: url('../public/bg.png');
  background-repeat: repeat;
  background-size: contain;
}
html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}