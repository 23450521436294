.footer_container {
  position: relative;
  margin: 40px 120px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}



.footer {
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4rem 0rem;
  margin-top: 4rem;
  border-top: solid white 4px;
}


.contacts_container {
  display: flex;
}

.phone_icon {
  display: flex;
  justify-content: center;
  padding: 0em 1.25rem;
  font-size: 2.75rem;
}

.contact_items {
  display: flex;
}

.contact_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.contact_heading {
  font-size: 1.2rem;
  font-weight: 500;
}

.contact_subheading {
  font-weight: 300;
  font-size: 1.05rem;
}

.address_container {
  display: flex;
  margin-top: 2rem;
}

.address_item {
  display: flex;
  flex-direction: column;
}

.address_heading {
  font-weight: 500;
  font-size: 1.2rem;
}

.address_subheading {
  font-weight: 300;
}

.map_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em 1.25rem;
  font-size: 2.75rem;
}

.four-point-star::after,
.four-point-star::before {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 48%);
  content: "\2726";
  font-size: 5rem;
}

.four-point-star::after {
  /* The foreground star */
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.four-point-star::before {
  /* The star shadow */
  color: transparent;
  text-shadow: 2px 3px 30px white;
}

.four-point-star {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 48%);
  bottom: 100.7%;
}

.map_container {
  margin: 0rem 2rem;
}

.info_container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .footer_container {
    margin: 0px 50px;
   
  }
  .map_container {
    margin-top: 2rem;
  }

  .footer{  flex-direction: column;
  align-items: center;}

}
