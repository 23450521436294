.page-section {
    position: fixed;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 80vh;
    transform: translateY(-50%);
}

.section-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-family: 'Klarissa';
    color: #fcc200;
}

.contact-text {
    color: white;
    flex: 1;
}

.contact-item {
    margin: 10px 0;
}

.contact-item p, h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.links-container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
    height: 30%;
}

.links-container i {
    color: white;
    font-size: 2rem;
    margin: 0 15px;
    cursor: pointer;
}

.links-container i:hover {
    scale: 1.2;
    transition: 0.2s;
    color: #fcc200;
}

.line__above {
    position: absolute;
    top: 10%;
    border: 2px solid white;
    height: 25%;
}

.line__below {
    position: absolute;
    bottom: 10%;
    border: 2px solid white;
    height: 25%;
}

@media screen and (max-width: 846px) {
    .links-container {
        flex-direction: row;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        padding: 25px 0;
    }
    .page-section {
        position: static;
        height: auto;
        padding: 25px 50px;
        padding-top: 40px;
        margin-bottom: 0;
    }

    .line__above, .line__below {
        display: none;
    }
}
