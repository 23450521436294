.menu {
    box-sizing: border-box;
    position: absolute;
    height: 40vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000000e0;
    padding: 20px;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Montserrat', sans-serif;
}