

.container {
    height: 80vh;
    overflow: hidden;
}

.container img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
    animation: slide 20s linear infinite;
}

@media screen and (min-width: 750px) {
    .container img {
        object-position: 0 30%;
    }
}

.title {
    font-size: 3rem;
    font-family: 'Klarissa';
    color: #fcc200;
    margin-bottom: 40px;
    padding: 0 40px;
    margin-top: 0;
}

.section {
    margin-top: 50px;
}

.text-container p {
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 1.5rem;
    padding: 0 40px;
    margin: 0;
    margin-bottom: 40px;
}

@media screen and (max-width: 1000px) {
    .text-container p {
        text-align: center;
    }

    .title {
        text-align: center;
    }
    
}

.carousel-container {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}