.title {
    font-family: 'Klarissa';
    text-align: center;
    font-size: 3rem;
    color: #fcc200;
    letter-spacing: 1.5px;
    margin: 0;
}

.page {
    padding-top: 100px;
}

.subtitle {
    font-family: 'hidayatullah';
    text-align: center;
    font-size: 2.3rem;
    color: #fcc200;
    margin: 0;
    margin-bottom: 10px;
}

.container {
    width: 70vw;
    margin: auto;
}

.tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: white;
    font-family: 'Kalligraaf';
    font-size: 2rem;
    border-bottom: 1px solid #fcc200;
    line-height: 1.4;
}

.tab {
    cursor: pointer;
}

.tab.selected {
    color: #fcc200;
}

@media screen and (max-width: 800px) {
    .container {
        width: 95vw;
    }

    .tabs-container {
        font-size: 1.6rem;
    }
}
