.intro-video-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #000;
  background-image: url('../../public/bg.png');
}

.intro-video {
  height: 100%;
}

/* @media screen and (max-width: 768px) {
  .intro-video {
      width: 100%;
  }
} */
