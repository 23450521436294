.Card-Container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 90%; 
    margin-left: 5%; margin-right: 5%;
}

.Card{  
    margin-top: 3rem;
    padding-top: 7px;
    height: 345px; width: 270px;
    background-color: #fff;
    border-radius: 10px;
    transition: all 300ms ease;
}
.Card:hover{
    transform: scale(1.05);
}

.Description{
    padding-top: 2px;
    margin-left: 0.8rem;
    display: inline-flex; height: 50px;
    gap: 0.9rem;
}
.Description img{
    border-radius: 50%;
    border: solid black 0.05px;
}
.Description-Text{
    text-align: left;
    display: flex; flex-direction: column;
    gap: 0rem;
}
.Description-Text h3{
  text-align: left;
  font-size: 19px;
  letter-spacing: 0.2px;
  padding-bottom: 0px; margin-bottom: 0px;
}
.Description-Text p{
    font-size: 13px;
    margin-top: 0rem;
    padding-bottom: 10px;
}

.Footer-Part{
    margin-top: 8px;
    display: inline-flex;
    gap: 1.1rem;
    width: 90%;
    margin-left: 5%; margin-right: 5%;
}
.Footer-Part p{
    font-size: 15px;
    margin-top: 2px;
}

.Registration-Link{
    background-color: #fff;
    border: solid 0.05px;
    border-radius: 10px;
    padding: 5px 8px;
    letter-spacing: 0.1px;
    transition: all 300ms ease;
}
.Registration-Link:hover{
    background-color: black;
    color: white;
}

.white-logos{
    background-color: black;
    object-fit: contain;
}
.large-logos{
    object-fit: contain;
}
.Footer-Part .Registration-Misc{
    background-color: #fff;
    border: solid 0.05px;
    border-radius: 10px;
    padding: 2px 5px 5px 5px;
    letter-spacing: 0.02px;
    font-size: 13.5px;
}

.tabs_events{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    flex-wrap: wrap;
    padding-top: 80px;
}

@media only screen and (max-width: 940px) {
    .tabs_events {
        padding-top: 80px;
    }
}
@media only screen and (max-width: 550px) {
    .tabs_events {
        padding-top: 20px;
        gap: 0px; margin: 0;
        display: flex;
        flex-direction: column;
    }
}

.tab_ele{
    color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    margin: 20px;
    border: 1px solid white;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 400ms ease;
}
.tab_ele:hover{
    color: black;   
    background-color: white;
}

@media(max-width: 1200px){
    .Card-Container{
        grid-template-columns: repeat(3 , 1fr);
    }
}
@media(max-width: 890px){
    .Card-Container{
        grid-template-columns: repeat(2 , 1fr);
    }
}
@media(max-width: 550px){
    .Card-Container{
        width: 80%;
        margin-left: 15%; margin-right: 10%;
        grid-template-columns: repeat(1 , 1fr);
    }
    .Description-text{
        margin-bottom: 20px;
    }
}