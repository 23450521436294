@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.navbar{
    background-color: transparent;
    display: flex;
    /* justify-content: space-evenly; */
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, transparent 100%);
    /* padding: 20px 0; */
    font-size: 2rem;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
    font-family: 'Montserrat', sans-serif;
}
.nav_ele_href{
    background-color: transparent;
    color: white;
    text-decoration: none;
}

.nav_ele_href:hover{
    color: #fcc200;
    transition: 0.5s;
}

.navbar__left {
    display: flex;
    align-items: center;
    width: 160px;
    overflow: hidden;
    height: 120px;
    padding: 0 20px;
}

.navbar__logo {
    cursor: pointer;
}

.navbar__left a {
    height: 100%;
}

.navbar__left a img {
    height: 100%;
}

.navbar__right {
    display: flex;
    /* flex: 1; */
    padding-top: 30px;
    justify-content: space-evenly;
    width: 72%;
    font-size: 1.2rem;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
}


.navbar__mobile {
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, transparent 100%);
    margin: 0;
    z-index: 400;
    box-sizing: border-box;
    padding: 10px;
    padding-right: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
}

.fa-bars {
    margin-top: 12px;
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
}

.navbar__top {
    height: 80%;
}

.navbar__mobile a {
    height: 100%;
}

.navbar__mobile a img {
    height: 100%;
}

.fa-times {
    color: white;
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 20px;
}

.solid__nav {
    background: linear-gradient(180deg, rgba(2,0,36,1) 55%, transparent 100%);
    transition: 0.6s;
}


@media screen and (max-width: 1000px) {
    .navbar__left {
        display: none;
    }
    .navbar {
        padding: 20px 0;
    }
    .navbar__right {
        width: 100%;
    }
    .nav_ele {
        font-size: 1.2rem;
    }
    .solid__nav {
        background: linear-gradient(180deg, rgba(2,0,36,1) 65%, transparent 100%);
        transition: 0.6s;
    }
}

